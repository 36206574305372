<template>
  <EmailForm
    ref="emailForm"
    :templates="emailTemplates"
    :defaultEmail="formData.email"
    :defaultSubject="formData.subject"
    :defaultBody="formData.body"
    :defaultReplyTo="formData.replyTo"
    :defaultFromName="formData.fromName"
    @template-selected="loadTemplate"
    @send="sendEmail"
    @cancel="closeForm"
  />
</template>

<script>
import EmailForm from '@/components/EmailForm/EmailForm.vue'

export default {
  name: 'WelcomeEmail',
  components: {
    EmailForm
  },
  data() {
    return {
      showForm: false,
      formData: {
        email: '',
        subject: '',
        body: '',
        replyTo: 'no-reply@koval-distillery.com',
        fromName: 'Koval CRM',
        user: {
          first_name: '',
          last_name: '',
          user_name: '',
          email: ''
        }
      },
      emailTemplates: [
        { id: 'select', label: 'Select template' },
        { id: 'template1', label: 'Portal account' },
        { id: 'template2', label: 'Portal account and koval-distillery.com' },
        { id: 'template3', label: 'Portal account and Google Worksuite' }
      ]
    }
  },
  methods: {
    loadTemplate(templateId) {
      this.$api
        .get(`/users/email-templates/${templateId}?random=${Math.random()}`, {
          responseType: 'blob'
        })
        .then(response => {
          this.formData.body = this.replaceTemplateVariables(response)
          this.$refs.emailForm.formData.body = this.formData.body
        })
        .catch(error => {
          this.formData.body = ''
          this.$refs.emailForm.formData.body = ''
          this.$form.makeToastError('Error fetching template')
          console.error('Error fetching template:', error)
        })
    },
    
    replaceTemplateVariables(template) {
      const user = this.formData.user
      const variables = {
        '{{first_name}}': user.first_name,
        '{{last_name}}': user.last_name,
        '{{first_name.last_name}}': (
          user.first_name +
          '.' +
          user.last_name
        ).toLowerCase(),
        '{{user_name}}': user.user_name,
        '{{password}}': 'PASSWORD'
      }

      return Object.keys(variables).reduce((acc, key) => {
        return acc.replace(new RegExp(key, 'g'), variables[key])
      }, template)
    },
    
    async sendEmail(data) {
      try {
        const formData = new FormData()
        formData.append('from_email', data.replyTo || 'no-reply@koval-distillery.com')
        formData.append('from_name', data.fromName)
        formData.append('email', data.email)
        formData.append('subject', data.subject)
        formData.append('body', data.body)
        
        if (data.replyTo) {
          formData.append('reply_to', data.replyTo)
        }
        
        // Add attachments if any
        if (data.attachments && data.attachments.length > 0) {
          data.attachments.forEach((file, index) => {
            formData.append(`attachment_${index}`, file)
          })
        }
        
        await this.$api.post('/send-email', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        
        this.$form.makeToastInfo('Email sent successfully')
        this.closeForm()
      } catch (error) {
        this.$form.makeToastError('Error sending email')
        console.error('Error sending email:', error)
      }
    },
    
    closeForm() {
      this.$refs.emailForm.showForm = false
    },
    
    show(payload) {
      console.log('WelcomeEmail.show:', payload)
      this.formData.user = payload.user
      this.formData.email = payload.user.email2
      this.formData.subject = payload.subject
      this.formData.body = payload.body
      
      this.$refs.emailForm.show({
        email: this.formData.email,
        subject: this.formData.subject,
        body: this.formData.body,
        replyTo: this.formData.replyTo,
        fromName: this.formData.fromName
      })
    }
  }
}
</script>
