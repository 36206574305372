<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(module || $route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @row-select="onRowSelect"
    >
      <div slot="afterFilter">
        <b-row v-if="showAddButton">
          <b-col>
            <b-button-group>
              <button
                class="btn btn-outline-dark btn"
                type="button"
                @click="addActivity()"
              >
                <i class="fa fa-toolbar fa-plus " /> Create
              </button>

              <button
                class="btn btn-outline-dark btn"
                type="button"
                @click="updateView()"
              >
                <font-awesome-icon icon="list" /> Change view
              </button>
            </b-button-group>
          </b-col>
          <hr />
        </b-row>
      </div>
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-warning btn-sm"
            @click="batchActivitySubmission(props.row.ID)"
          >
            <font-awesome-icon icon="copy" />
          </button>

          <button
            class="btn btn-primary btn-sm"
            @click="viewActivity(props.row.ID)"
          >
            <font-awesome-icon icon="eye" />
          </button>

          <button
            class="btn btn-success btn-sm"
            @click="editActivity(props.row.ID)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="deleteActivity(props.row.ID)"
          >
            <font-awesome-icon icon="trash" />
          </button>
          <button
            class="btn btn-dark btn-sm"
            @click="showContentModal(props.row.ID)"
            title="Preview"
          >
            <font-awesome-icon icon="search" />
          </button>

          <button
            :class="[
              props.row['FilesCount'] !== '0'
                ? 'btn btn-secondary btn-sm'
                : 'btn btn-outline-secondary btn-sm'
            ]"
            @click="toggleFiles(props.row['ID'])"
          >
            <font-awesome-icon icon="download" />
          </button>
        </div>
      </div>

      <span slot-scope="props" slot="child_row">
        <files-container
          ref="files"
          :module-id="$route.meta.module.id"
          :entity-id="props.row.ID"
        />
      </span>
    </table-custom>

    <b-modal
      ref="preview-activity-modal"
      centered
      hide-footer
      size="lg"
      :title="previewModal.title"
    >
      <b-card>
        <b-card-body>
          <b-row>
            <b-col lg="3">
              <strong>User: </strong> {{ previewModal.userName }}
            </b-col>
            <b-col lg="3">
              <strong>Category: </strong> {{ previewModal.category }}
            </b-col>
            <b-col lg="6">
              <strong>Account: </strong> {{ previewModal.account }}
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="3">
              <strong>Date: </strong> {{ previewModal.activityDate }}
            </b-col>
            <b-col lg="3">
              <strong>Time start: </strong> {{ previewModal.timeStart }}
            </b-col>
            <b-col lg="3">
              <strong>Time end: </strong> {{ previewModal.timeEnd }}
            </b-col>
          </b-row>
          <b-row />
          <b-row>
            <b-col>
              <strong>Subject: </strong> {{ previewModal.subject }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <strong>Description: </strong>
              <p>{{ previewModal.body }}</p>
            </b-col>
          </b-row>

          <hr />
          <b-button
            size="sm"
            v-b-toggle.collapse-2
            class="m-1"
            variant="outline-dark"
          >
            <font-awesome-icon icon="arrow-down" /> Tastings
          </b-button>

          <b-collapse id="collapse-2">
            <hr />
            <b-row>
              <b-col lg="3">
                <strong>Visitors: </strong>{{ previewModal.Visitors }}
              </b-col>
              <b-col lg="3">
                <strong>Expenses: </strong>{{ previewModal.Expenses }}
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col cols="3">
                <strong>White Whisky: </strong>{{ previewModal.WhiteWhisky }}
              </b-col>
              <b-col cols="3">
                <strong>Whisky: </strong>{{ previewModal.Whisky }}
              </b-col>
              <b-col cols="3">
                <strong>Gin: </strong>{{ previewModal.Gin }}
              </b-col>
              <b-col cols="3">
                <strong>Vodka: </strong>{{ previewModal.Vodka }}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <strong>Liqueurs: </strong>{{ previewModal.Liqueurs }}
              </b-col>
              <b-col cols="3">
                <strong>Rum: </strong>{{ previewModal.Rum }}
              </b-col>
              <b-col cols="3">
                <strong>GinGiftPack: </strong>{{ previewModal.GinGiftPack }}
              </b-col>
              <b-col cols="3">
                <strong>WhiskeyGiftPack: </strong
                >{{ previewModal.WhiskeyGiftPack }}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <strong>Other: </strong>{{ previewModal.Other }}
              </b-col>
            </b-row>
          </b-collapse>
        </b-card-body>
      </b-card>
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button
          v-if="
            isReviewer &&
              !previewModal.Reviewed &&
              $permitted(controls.buttons.set_reviewed.id).visible
          "
          variant="warning"
          class="m-1"
          @click="setReviewedModal()"
        >
          <font-awesome-icon icon="signature" /> Mark as Reviewed
        </b-button>
        <b-button variant="outline-dark" class="m-1" @click="closeModal()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>

    <b-modal
      ref="batch-activity-modal"
      centered
      hide-footer
      size="lg"
      :title="batchActivityModal.title"
    >
      <b-card>
        <b-card-body />
      </b-card>
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button class="m-1">
          <font-awesome-icon icon="signature" /> Run
        </b-button>
        <b-button
          variant="outline-dark"
          class="m-1"
          @click="closeBatchActivityModal()"
        >
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import TableCustom from '@/components/TableCustom'

import FilesContainer from '@/components/FilesContainer'

export default {
  props: {
    accountId: {
      type: [Number, String],
      default: ''
    },
    contactId: {
      type: [Number, String],
      default: ''
    },
    userId: {
      type: [Number, String],
      default: ''
    },
    projectId: {
      type: [Number, String],
      default: ''
    },
    filter:{
      type: String,
      default: undefined
    },
    mode: {
      type: [Number, String],
      default: ''
    },
    selectableRows: {
      type: Boolean,
      default: false
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    module: {
      type: Object,
      default: undefined
    }
  },
  name: 'ActivitiesTable',
  components: {
    FilesContainer,
    TableCustom
  },
  data: function () {
    return {
      appliedFilters: [],
      rawData: {},
      controls: {
        buttons: {
          set_reviewed: {
            id: 'button:set_reviewed'
          }
        }
      },
      isReviewer: false,

      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: 'ID',
          showChildRowToggler: false,
          columns: [
            'ID',
            'Submitter',
            'Category',
            'Account Name',
            'Account Category',
            'Country',
            'State',
            'City',
            'Activity Date',
            'Subject',
            'Content',
            'Reviewed',
            'Actions'
          ],
          filterable: [
            'ID',
            'Submitter',
            'Category',
            'Account Name',
            'Account Category',
            'Country',
            'State',
            'City',
            'Activity Date',
            'Subject',
            'Content',
            'Reviewed'
          ],
          //showActions: true,
          perPage: 50,
          showCustomActions: true,
          showChildRows: true,
          selectableRows: this.selectableRows,
        }
      },

      previewModal: {
        ID: '',
        Reviewed: false,
        title: '',
        userName: '',
        category: '',
        account: '',
        activityDate: '',
        timeStart: '',
        timeEnd: '',
        subject: '',
        body: '',
        Visitors: '',
        Expenses: '',
        WhiteWhisky: '',
        Whisky: '',
        Gin: '',
        Vodka: '',
        Liqueurs: '',
        GinGiftPack: '',
        WhiskeyGiftPack: '',
        Other: ''
      },
      batchActivityModal: {
        title: ''
      }
    }
  },
  computed: {},
  mounted () {
    console.log('ActivitiesTable mounted. this.userId:', this.userId)

    if (this.contactId || this.accountId || this.userId  || this.projectId) this.getData()
  },
  methods: {
    toggleFiles (id) {
      this.$refs.dataTable.toggleChildRow(id)
    },

    updateView () {
      let set1 = [
        'ID',
        'Submitter',
        'Category',
        'Account Name',
        'Account Category',
        'Country',
        'State',
        'City',
        'Activity Date',
        'Subject',
        'Reviewed',
        'Actions'
      ]
      let set2 = [
        'ID',
        'Submitter',
        'Category',
        'Account Name',
        'Activity Date',
        'Subject',
        'Content',
        'Reviewed',
        'Actions'
      ]

      console.log('updateView')

      if (this.dataTable.view === 1) {
        this.$refs.dataTable.setVisibleColumns(set1)

        //this.dataTable.options.columns = set1

        this.dataTable.view = 2
      } else {
        this.$refs.dataTable.setVisibleColumns(set2)
        //this.dataTable.options.columns = set2

        this.dataTable.view = 1
      }

      //const t = this.dataTable.dataSet

      this.$refs.dataTable.refresh()

      //this.dataTable.dataSet = t
    },
    onFilter () {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length
    },
    async getData (payload) {
      this.appliedFilters = payload

      let self = this

      this.dataTable.isLoading = true

      // this.dataTable.dataSet = [];

      let method = 'post',
        url = 'activities'

      if (this.accountId) {
        method = 'get'
        url = `accounts/${this.accountId}/activities`
      }

      if (this.contactId) {
        method = 'get'
        url = `contacts/${this.contactId}/activities`
      }

      if (this.userId) {
        method = 'get'
        url = `user/${this.userId}/activities`
      }

      //Activities by project
      if (this.projectId && !this.filter) {
        method = 'get'
        url = `projects/${this.projectId}/activities`
      }   
      
      //Activities can be included in the project [filter='available' passed from the project page] 
      if (this.projectId && this.filter) {
        method = 'get'
        url = `projects/${this.projectId}/activities/${this.filter}`
      }
      
      if (this.mode === 'review') url = 'activities/review'
      if (this.mode === 'my') url = 'activities/my'
      if (this.mode === 'tasting') url = 'activities/tasting'

      console.log('Activities.projectId:', this.projectId,'; filter:', this.filter)

      console.log('ActivitiesTable.getData.url:', url)

      return this.$api[method](url, payload)
        .then(response => {
          self.dataTable.isLoading = false

          self.$emit('loaded', response.length)

          self.dataTable.dataSet = response

          if (response.length === 0) return

          //20210131 #316 global search
          //self.$emit("loaded", self.dataTable.dataSet.length)
        })
        .catch(error => {
          console.log(error)
          self.dataTable.isLoading = false
          self.$form.msgBoxOk('Error occured')
        })
    },
    addActivity: function () {
      this.$router.push({
        name: 'Activity submission',
        params: {
          action: 'create',
          params: { account_id: this.accountId }
        }
      })
    },

    viewActivity: function (id) {
      this.$router.push({
        name: 'Activity submission',
        params: {
          action: 'view',
          id: id
        }
      })
    },
    editActivity: function (id) {
      this.$router.push({
        name: 'Activity submission',
        params: {
          action: 'edit',
          id: id
        }
      })
    },
    deleteActivity: async function (id) {
      let activity = this.dataTable.dataSet.find(item => item.ID === id)

      let confirm = await this.$form.showConfirmation(
        `Activity #${activity.ID} will be deleted. Do you want to proceed?`
      )

      if (!confirm) return

      let self = this

      this.$api
        .delete(`activities/${activity.ID}`)
        .then(() => {
          self.$form.makeToastInfo('Activity deleted')

          self.getData(self.appliedFilters)
        })
        .catch(response => {
          console.log(response)

          self.$form.makeToastError(response.data.message)
        })
    },
    batchActivitySubmission (id) {
      //let activity = this.dataTable.dataSet.find(item => item.ID === id);

      this.$router.push({
        name: 'Batch activity submission',
        params: {
          action: 'create',
          activity_id: id
        }
      })
    },
    onRowSelect (e) {

      console.log('ActivitiesTable.onRowSelect', e)
      
      this.$emit('row-select', e)
    },
    showContentModal (id) {
      let activity = this.dataTable.dataSet.find(item => item.ID === id)

      if (!activity) return

      this.previewModal.title = activity['Subject']

      //this.previewModal.htmlContent = activity['Content']

      this.previewModal.ID = activity.ID

      this.previewModal.title = `Activity #${activity.ID}`

      this.previewModal.userName = activity['Submitter']
      this.previewModal.category = activity['Category']
      this.previewModal.account = activity['Account Name']
      this.previewModal.activityDate = activity['Activity Date']
      this.previewModal.timeStart = activity['Start Time']
      this.previewModal.timeEnd = activity['End Time']
      this.previewModal.subject = activity['Subject']
      this.previewModal.body = activity['Content']

      this.previewModal.Visitors = activity['Visitors']
      this.previewModal.Expenses = activity['Expenses']
      this.previewModal.WhiteWhisky = activity['WhiteWhisky']
      this.previewModal.Whisky = activity['Whisky']
      this.previewModal.Gin = activity['Gin']
      this.previewModal.Rum = activity['Rum']
      this.previewModal.Vodka = activity['Vodka']
      this.previewModal.Liqueurs = activity['Liqueurs']
      this.previewModal.GinGiftPack = activity['GinGiftPack']
      this.previewModal.WhiskeyGiftPack = activity['WhiskeyGiftPack']
      this.previewModal.Other = activity['Other']
      this.previewModal.Reviewed = activity['Reviewed'] === 'Yes'

      this.$refs['preview-activity-modal'].show()
    },
    setReviewedModal () {
      this.$api
        .put(`activities/${this.previewModal.ID}/reviewed`)
        .then(response => {
          this.previewModal.Reviewed = true

          this.$form.msgBoxOk(response.message)
        })
        .catch(error => {
          console.log(error)

          this.$form.msgBoxOk(error.message)
        })
    },
    closeModal: function () {
      this.$refs['preview-activity-modal'].hide()
    }
  },
  watch: {}
}
</script>

<style scoped></style>
