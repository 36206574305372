var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-wrapper"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isMaximized,
      expression: "isMaximized"
    }],
    class: ['modal-backdrop', {
      hidden: !_vm.showForm
    }]
  }), _c('div', {
    ref: "emailForm",
    class: ['email-form bg-white', {
      minimized: _vm.isMinimized,
      hidden: !_vm.showForm
    }],
    style: _vm.formStyles
  }, [_c('b-row', {
    staticClass: "border-bottom m-0 header-drag",
    on: {
      "mousedown": _vm.handleMouseDown
    }
  }, [_c('b-col', {
    staticClass: "d-flex justify-content-between align-items-center p-2 header-bg",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0 header-title"
  }, [_vm._v("Compose Email")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-button', {
    staticClass: "p-0 mr-2 custom-btn",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.toggleMaximize
    }
  }, [_c('i', {
    class: ['fa', _vm.isMaximized ? 'fa-compress' : 'fa-expand']
  })]), _c('b-button', {
    staticClass: "p-0 custom-btn",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.onMinimizeClick
    }
  }, [_c('i', {
    class: ['fa', _vm.isMinimized ? 'fa-chevron-up' : 'fa-chevron-down']
  })])], 1)])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isMinimized,
      expression: "!isMinimized"
    }],
    staticClass: "form-content",
    style: _vm.contentStyles
  }, [_c('div', {
    staticClass: "form-row mb-2"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.fromName,
      expression: "formData.fromName"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "fromName",
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.formData.fromName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "fromName", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-row mb-2"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.email,
      expression: "formData.email"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "email",
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.formData.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "email", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-row mb-2"
  }, [_vm._m(2), _c('div', {
    staticClass: "col-9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.replyTo,
      expression: "formData.replyTo"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "replyTo",
      "type": "text"
    },
    domProps: {
      "value": _vm.formData.replyTo
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "replyTo", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-row mb-2"
  }, [_vm._m(3), _c('div', {
    staticClass: "col-9"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.subject,
      expression: "formData.subject"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "subject",
      "type": "text",
      "required": ""
    },
    domProps: {
      "value": _vm.formData.subject
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formData, "subject", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-row mb-2"
  }, [_vm._m(4), _c('div', {
    staticClass: "col-9"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formData.template,
      expression: "formData.template"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "id": "templates"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.formData, "template", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.onTemplateSelectChange]
    }
  }, _vm._l(_vm.templates, function (option) {
    return _c('option', {
      key: option.id,
      domProps: {
        "value": option
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")]);
  }), 0)])]), _c('rich-text-editor', {
    staticClass: "mb-3",
    model: {
      value: _vm.formData.body,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "body", $$v);
      },
      expression: "formData.body"
    }
  }), _c('div', {
    staticClass: "attachments-section mb-3"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-1"
  }, [_c('label', {
    staticClass: "mb-0"
  }, [_vm._v("Attachments")]), _c('b-button', {
    attrs: {
      "variant": "outline-secondary",
      "size": "sm"
    },
    on: {
      "click": _vm.triggerFileInput
    }
  }, [_c('i', {
    staticClass: "fa fa-paperclip"
  }), _vm._v(" Attach File ")]), _c('input', {
    ref: "fileInput",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "multiple": ""
    },
    on: {
      "change": _vm.addAttachment
    }
  })], 1), _vm.formData.attachments.length > 0 ? _c('div', {
    staticClass: "attached-files-list"
  }, _vm._l(_vm.formData.attachments, function (file, index) {
    return _c('div', {
      key: index,
      staticClass: "attached-file d-flex justify-content-between align-items-center p-1"
    }, [_c('div', {
      staticClass: "file-name text-truncate"
    }, [_vm._v(_vm._s(file.name))]), _c('b-button', {
      staticClass: "p-0 text-danger",
      attrs: {
        "variant": "link"
      },
      on: {
        "click": function click($event) {
          return _vm.removeAttachment(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-times"
    })])], 1);
  }), 0) : _vm._e()])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isMinimized,
      expression: "!isMinimized"
    }],
    staticClass: "form-footer position-absolute bottom-1 w-100 p-2 bg-white footer-border"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1 width10",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": _vm.onSendClick
    }
  }, [_c('i', {
    staticClass: "fa fa-paper-plane mr-1"
  }), _vm._v(" Send ")]), _c('b-button', {
    staticClass: "m-1 width10",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": _vm.onCancelClick
    }
  }, [_vm._v(" Cancel ")])], 1)])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-3 d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mb-0",
    attrs: {
      "for": "fromName"
    }
  }, [_vm._v("From:")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-3 d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mb-0",
    attrs: {
      "for": "email"
    }
  }, [_vm._v("To:")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-3 d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mb-0",
    attrs: {
      "for": "replyTo"
    }
  }, [_vm._v("Reply To:")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-3 d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mb-0",
    attrs: {
      "for": "subject"
    }
  }, [_vm._v("Subject:")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-3 d-flex align-items-center"
  }, [_c('label', {
    staticClass: "mb-0",
    attrs: {
      "for": "templates"
    }
  }, [_vm._v("Templates:")])]);

}]

export { render, staticRenderFns }