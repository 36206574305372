<template>
  <div class="form-wrapper">
    <div
      v-show="isMaximized"
      :class="['modal-backdrop', { hidden: !showForm }]"
    ></div>

    <div
      ref="emailForm"
      :class="[
        'email-form bg-white',
        { minimized: isMinimized, hidden: !showForm }
      ]"
      :style="formStyles"
    >
      <!-- Header -->
      <b-row class="border-bottom m-0 header-drag" @mousedown="handleMouseDown">
        <b-col
          cols="12"
          class="d-flex justify-content-between align-items-center p-2 header-bg"
        >
          <h5 class="mb-0 header-title">Compose Email</h5>
          <div class="d-flex align-items-center">
            <b-button
              variant="link"
              class="p-0 mr-2 custom-btn"
              @click="toggleMaximize"
            >
              <i :class="['fa', isMaximized ? 'fa-compress' : 'fa-expand']" />
            </b-button>
            <b-button
              variant="link"
              class="p-0 custom-btn"
              @click="onMinimizeClick"
            >
              <i
                :class="[
                  'fa',
                  isMinimized ? 'fa-chevron-up' : 'fa-chevron-down'
                ]"
              />
            </b-button>
          </div>
        </b-col>
      </b-row>

      <!-- Form Content -->
      <div v-show="!isMinimized" class="form-content" :style="contentStyles">
        <div class="form-row mb-2">
          <div class="col-3 d-flex align-items-center">
            <label for="fromName" class="mb-0">From:</label>
          </div>
          <div class="col-9">
            <input
              id="fromName"
              v-model="formData.fromName"
              type="text"
              class="form-control"
              required
            />
          </div>
        </div>
        
        <div class="form-row mb-2">
          <div class="col-3 d-flex align-items-center">
            <label for="email" class="mb-0">To:</label>
          </div>
          <div class="col-9">
            <input
              id="email"
              v-model="formData.email"
              type="text"
              class="form-control"
              required
            />
          </div>
        </div>
        
        <div class="form-row mb-2">
          <div class="col-3 d-flex align-items-center">
            <label for="replyTo" class="mb-0">Reply To:</label>
          </div>
          <div class="col-9">
            <input
              id="replyTo"
              v-model="formData.replyTo"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        
        <div class="form-row mb-2">
          <div class="col-3 d-flex align-items-center">
            <label for="subject" class="mb-0">Subject:</label>
          </div>
          <div class="col-9">
            <input
              id="subject"
              v-model="formData.subject"
              type="text"
              class="form-control"
              required
            />
          </div>
        </div>
        
        <div class="form-row mb-2">
          <div class="col-3 d-flex align-items-center">
            <label for="templates" class="mb-0">Templates:</label>
          </div>
          <div class="col-9">
            <select
              id="templates"
              v-model="formData.template"
              class="form-control custom-select"
              @change="onTemplateSelectChange"
            >
              <option 
                v-for="option in templates" 
                :key="option.id" 
                :value="option"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
        <rich-text-editor v-model="formData.body" class="mb-3" />
        
        <!-- Attachments Section -->
        <div class="attachments-section mb-3">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <label class="mb-0">Attachments</label>
            <b-button variant="outline-secondary" size="sm" @click="triggerFileInput">
              <i class="fa fa-paperclip"></i> Attach File
            </b-button>
            <input 
              type="file" 
              ref="fileInput" 
              class="d-none" 
              @change="addAttachment" 
              multiple
            />
          </div>
          
          <!-- Attached Files List -->
          <div v-if="formData.attachments.length > 0" class="attached-files-list">
            <div 
              v-for="(file, index) in formData.attachments" 
              :key="index"
              class="attached-file d-flex justify-content-between align-items-center p-1"
            >
              <div class="file-name text-truncate">{{ file.name }}</div>
              <b-button variant="link" class="p-0 text-danger" @click="removeAttachment(index)">
                <i class="fa fa-times"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div
        v-show="!isMinimized"
        class="form-footer position-absolute bottom-1 w-100 p-2 bg-white footer-border"
      >
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1 width10"
            @click="onSendClick"
          >
            <i class="fa fa-paper-plane mr-1"></i> Send
          </b-button>
          <b-button
            variant="outline-dark"
            class="m-1 width10"
            @click="onCancelClick"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineInput from '@/components/InlineInput'
import InlineSelect from '@/components/InlineSelect'
import RichTextEditor from '@/components/RichTextEditor'

export default {
  name: 'EmailForm',
  components: {
    InlineInput,
    InlineSelect,
    RichTextEditor
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    templates: {
      type: Array,
      default: () => [{ id: 'select', label: 'Select template' }]
    },
    defaultEmail: {
      type: String,
      default: ''
    },
    defaultSubject: {
      type: String,
      default: ''
    },
    defaultBody: {
      type: String,
      default: ''
    },
    defaultReplyTo: {
      type: String,
      default: ''
    },
    defaultFromName: {
      type: String,
      default: ''
    }
  },
  emits: ['template-selected', 'send', 'cancel'],
  data() {
    return {
      isMaximized: false,
      isMinimized: false,
      showForm: false,
      position: { x: null, y: null },
      isDragging: false,
      dragOffset: { x: 0, y: 0 },
      formData: {
        email: this.defaultEmail,
        subject: this.defaultSubject,
        body: this.defaultBody,
        replyTo: this.defaultReplyTo,
        fromName: this.defaultFromName,
        template: this.templates[0] || { id: 'select', label: 'Select template' },
        attachments: []
      }
    }
  },
  computed: {
    formStyles() {
      if (this.isMaximized) {
        return {
          width: '80%',
          height: '80%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          resize: 'none'
        }
      }

      const styles = {
        width: '580px',
        right: '20px',
        bottom: '20px'
      }

      if (this.position.x !== null && this.position.y !== null) {
        styles.right = 'auto'
        styles.bottom = 'auto'
        styles.left = `${this.position.x}px`
        styles.top = `${this.position.y}px`
      }

      if (this.isMinimized) {
        styles.height = '40px'
        styles.resize = 'none'
      } else {
        styles.height = '80vh'
        styles.minHeight = '80vh'
        styles.resize = 'both'
      }

      return styles
    },
    contentStyles() {
      const baseStyles = {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: '1rem'
      }

      if (this.isMaximized) {
        return {
          ...baseStyles,
          height: 'calc(100% - 100px)'
        }
      }

      return {
        ...baseStyles,
        height: 'calc(100% - 100px)'
      }
    }
  },
  watch: {
    isVisible(newVal) {
      this.showForm = newVal
    },
    defaultEmail(newVal) {
      this.formData.email = newVal
    },
    defaultSubject(newVal) {
      this.formData.subject = newVal
    },
    defaultBody(newVal) {
      this.formData.body = newVal
    },
    defaultReplyTo(newVal) {
      this.formData.replyTo = newVal
    },
    defaultFromName(newVal) {
      this.formData.fromName = newVal
    },
    templates(newVal) {
      if (newVal.length > 0 && this.formData.template.id === 'select') {
        this.formData.template = newVal[0]
      }
    }
  },
  methods: {
    onTemplateSelectChange() {
      if (this.formData.template.id === 'select') {
        this.formData.body = ''
        return
      }
      
      this.$emit('template-selected', this.formData.template.id)
    },
    
    async onSendClick() {
      const confirmed = await this.$form.showConfirmation(
        'Are you sure you want to send this email?'
      )

      if (confirmed) {
        this.$emit('send', {
          email: this.formData.email,
          subject: this.formData.subject,
          body: this.formData.body,
          replyTo: this.formData.replyTo,
          fromName: this.formData.fromName,
          attachments: this.formData.attachments
        })
      }
    },
    
    triggerFileInput() {
      this.$refs.fileInput.click()
    },
    
    addAttachment(event) {
      const files = event.target.files
      if (!files || files.length === 0) return
      
      for (let i = 0; i < files.length; i++) {
        this.formData.attachments.push(files[i])
      }
      
      // Reset file input to allow selecting the same file again
      event.target.value = null
    },
    
    removeAttachment(index) {
      this.formData.attachments.splice(index, 1)
    },
    
    onCancelClick() {
      this.showForm = false
      this.$emit('cancel')
    },
    
    onMinimizeClick() {
      this.isMinimized = !this.isMinimized
      if (this.isMinimized) {
        this.isMaximized = false
      }
    },

    toggleMaximize() {
      this.isMaximized = !this.isMaximized
      this.isMinimized = false
      if (this.isMaximized) {
        this.position = { x: null, y: null }
      }
    },
    
    show(payload = {}) {
      this.formData.email = payload.email || this.defaultEmail
      this.formData.subject = payload.subject || this.defaultSubject
      this.formData.body = payload.body || this.defaultBody
      this.formData.replyTo = payload.replyTo || this.defaultReplyTo
      this.formData.fromName = payload.fromName || this.defaultFromName
      this.formData.attachments = payload.attachments || []
      
      this.showForm = true
    },
    
    restore() {
      if (this.showForm && this.isMinimized) {
        this.isMinimized = false
      } else if (!this.showForm) {
        this.showForm = true
        this.isMinimized = false
      }
    },
    
    handleMouseDown(e) {
      if (this.isMaximized) return

      this.isDragging = true
      const rect = this.$refs.emailForm.getBoundingClientRect()
      this.dragOffset = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      }

      document.addEventListener('mousemove', this.handleMouseMove)
      document.addEventListener('mouseup', this.handleMouseUp)
    },

    handleMouseMove(e) {
      if (!this.isDragging) return

      e.preventDefault()
      const x = e.clientX - this.dragOffset.x
      const y = e.clientY - this.dragOffset.y

      const maxX = window.innerWidth - this.$refs.emailForm.offsetWidth
      const maxY = window.innerHeight - this.$refs.emailForm.offsetHeight

      this.position = {
        x: Math.min(Math.max(0, x), maxX),
        y: Math.min(Math.max(0, y), maxY)
      }
    },

    handleMouseUp() {
      this.isDragging = false
      document.removeEventListener('mousemove', this.handleMouseMove)
      document.removeEventListener('mouseup', this.handleMouseUp)
    }
  }
}
</script>

<style scoped>
.width10 {
  width: 10em;
}
.form-wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1040;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}

.email-form {
  position: fixed;
  z-index: 1050;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.3rem;
  border: 0.3px solid rgba(47, 53, 58, 0.2);
  transition: all 0.3s ease;
  max-height: calc(100vh - 40px);
  overflow: hidden;
}

.hidden {
  display: none;
}

.header-drag {
  cursor: move;
  user-select: none;
}

.header-bg {
  background-color: #2f353a;
}

.header-title {
  color: #f8f9fa;
  font-weight: 400;
}

.footer-border {
  border-top: 0.3px solid rgba(47, 53, 58, 0.2) !important;
}

.custom-btn {
  color: #f8f9fa;
}

.email-form :deep(.form-control) {
  min-height: unset;
  border: 0.3px solid rgba(47, 53, 58, 0.2);
}

.email-form :deep(.form-control:focus) {
  border-color: rgba(47, 53, 58, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.1);
}

.email-form :deep(.custom-select) {
  border: 0.3px solid rgba(47, 53, 58, 0.2);
  background-color: white;
  color: #2f353a;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
}

.email-form :deep(.custom-select:focus) {
  border-color: rgba(47, 53, 58, 0.4);
  box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.1);
}

.minimized {
  resize: none !important;
}

.attachments-section {
  border: 1px solid rgba(47, 53, 58, 0.2);
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.attached-files-list {
  max-height: 150px;
  overflow-y: auto;
}

.attached-file {
  border-bottom: 1px solid rgba(47, 53, 58, 0.1);
}

.attached-file:last-child {
  border-bottom: none;
}

.file-name {
  max-width: 200px;
}
</style>
