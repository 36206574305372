var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('EmailForm', {
    ref: "emailForm",
    attrs: {
      "templates": _vm.emailTemplates,
      "defaultEmail": _vm.formData.email,
      "defaultSubject": _vm.formData.subject,
      "defaultBody": _vm.formData.body,
      "defaultReplyTo": _vm.formData.replyTo,
      "defaultFromName": _vm.formData.fromName
    },
    on: {
      "template-selected": _vm.loadTemplate,
      "send": _vm.sendEmail,
      "cancel": _vm.closeForm
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }