var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "".concat(_vm.$customTable.getCustomTableName(_vm.module || _vm.$route.meta.module)),
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    on: {
      "row-select": _vm.onRowSelect
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('button', {
          staticClass: "btn btn-warning btn-sm",
          on: {
            "click": function click($event) {
              return _vm.batchActivitySubmission(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "copy"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-primary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.viewActivity(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              return _vm.editActivity(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.deleteActivity(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-dark btn-sm",
          attrs: {
            "title": "Preview"
          },
          on: {
            "click": function click($event) {
              return _vm.showContentModal(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "search"
          }
        })], 1), _c('button', {
          class: [props.row['FilesCount'] !== '0' ? 'btn btn-secondary btn-sm' : 'btn btn-outline-secondary btn-sm'],
          on: {
            "click": function click($event) {
              return _vm.toggleFiles(props.row['ID']);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "download"
          }
        })], 1)])]);
      }
    }, {
      key: "child_row",
      fn: function fn(props) {
        return _c('span', {}, [_c('files-container', {
          ref: "files",
          attrs: {
            "module-id": _vm.$route.meta.module.id,
            "entity-id": props.row.ID
          }
        })], 1);
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "afterFilter"
    },
    slot: "afterFilter"
  }, [_vm.showAddButton ? _c('b-row', [_c('b-col', [_c('b-button-group', [_c('button', {
    staticClass: "btn btn-outline-dark btn",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.addActivity();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-toolbar fa-plus"
  }), _vm._v(" Create ")]), _c('button', {
    staticClass: "btn btn-outline-dark btn",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.updateView();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "list"
    }
  }), _vm._v(" Change view ")], 1)])], 1), _c('hr')], 1) : _vm._e()], 1)]), _c('b-modal', {
    ref: "preview-activity-modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.previewModal.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("User: ")]), _vm._v(" " + _vm._s(_vm.previewModal.userName) + " ")]), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Category: ")]), _vm._v(" " + _vm._s(_vm.previewModal.category) + " ")]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', [_vm._v("Account: ")]), _vm._v(" " + _vm._s(_vm.previewModal.account) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Date: ")]), _vm._v(" " + _vm._s(_vm.previewModal.activityDate) + " ")]), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Time start: ")]), _vm._v(" " + _vm._s(_vm.previewModal.timeStart) + " ")]), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Time end: ")]), _vm._v(" " + _vm._s(_vm.previewModal.timeEnd) + " ")])], 1), _c('b-row'), _c('b-row', [_c('b-col', [_c('strong', [_vm._v("Subject: ")]), _vm._v(" " + _vm._s(_vm.previewModal.subject) + " ")])], 1), _c('b-row', [_c('b-col', [_c('strong', [_vm._v("Description: ")]), _c('p', [_vm._v(_vm._s(_vm.previewModal.body))])])], 1), _c('hr'), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-2",
      modifiers: {
        "collapse-2": true
      }
    }],
    staticClass: "m-1",
    attrs: {
      "size": "sm",
      "variant": "outline-dark"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "arrow-down"
    }
  }), _vm._v(" Tastings ")], 1), _c('b-collapse', {
    attrs: {
      "id": "collapse-2"
    }
  }, [_c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Visitors: ")]), _vm._v(_vm._s(_vm.previewModal.Visitors) + " ")]), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('strong', [_vm._v("Expenses: ")]), _vm._v(_vm._s(_vm.previewModal.Expenses) + " ")])], 1), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('strong', [_vm._v("White Whisky: ")]), _vm._v(_vm._s(_vm.previewModal.WhiteWhisky) + " ")]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('strong', [_vm._v("Whisky: ")]), _vm._v(_vm._s(_vm.previewModal.Whisky) + " ")]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('strong', [_vm._v("Gin: ")]), _vm._v(_vm._s(_vm.previewModal.Gin) + " ")]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('strong', [_vm._v("Vodka: ")]), _vm._v(_vm._s(_vm.previewModal.Vodka) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('strong', [_vm._v("Liqueurs: ")]), _vm._v(_vm._s(_vm.previewModal.Liqueurs) + " ")]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('strong', [_vm._v("Rum: ")]), _vm._v(_vm._s(_vm.previewModal.Rum) + " ")]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('strong', [_vm._v("GinGiftPack: ")]), _vm._v(_vm._s(_vm.previewModal.GinGiftPack) + " ")]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('strong', [_vm._v("WhiskeyGiftPack: ")]), _vm._v(_vm._s(_vm.previewModal.WhiskeyGiftPack) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('strong', [_vm._v("Other: ")]), _vm._v(_vm._s(_vm.previewModal.Other) + " ")])], 1)], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_vm.isReviewer && !_vm.previewModal.Reviewed && _vm.$permitted(_vm.controls.buttons.set_reviewed.id).visible ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.setReviewedModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "signature"
    }
  }), _vm._v(" Mark as Reviewed ")], 1) : _vm._e(), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1), _c('b-modal', {
    ref: "batch-activity-modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.batchActivityModal.title
    }
  }, [_c('b-card', [_c('b-card-body')], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "signature"
    }
  }), _vm._v(" Run ")], 1), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeBatchActivityModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }